@import "../../utils/theme.scss";

header {
  position: fixed;
  display: flex;
  justify-self: center;
  justify-content: space-between;
  width: 100%;
  height: 70px;
  // max-width: 900px;
  background: rgba(255, 255, 255, 0.2);
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  border: 1px solid rgba(255, 255, 255, 0.3);
  z-index: 10;

  img {
    width: 50%;
    max-width: 250px;
    object-fit: contain;
    padding-left: 1rem;
  }

  @include sm {
    position: absolute;
    background: none;
    backdrop-filter: none;
    border: none;
    box-shadow: none;
    top: 20px;
    justify-content: center;

    img {
      padding: 0;
    }
  }

  .pause-icon,
  .play-icon,
  .info-icon,
  .gear-icon {
    width: 50px;
    transition: 0.3s ease-in-out;

    //TODO: hover state
    &:hover {
      transform: translateY(-5px);
    }
  }

  .pause-icon,
  .info-icon {
    padding: 10px;
  }

  .play-icon {
    padding: 5px;
  }
}
