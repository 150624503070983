.game-wrapper {
  grid-area: game;
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  aspect-ratio: 1/1;
  max-width: 900px;
  width: 100vw;
  padding: 2rem 1rem;
  margin-bottom: 2rem;
  @media (min-height: 1500px) {
    max-width: 1250px;
  }

  .postgame-btn {
    position: absolute;
    top: -20px;
  }

  &.light [id*="minigame"],
  &.light [id*="cell"] {
    border-color: black;
  }
  &.dark [id*="minigame"],
  &.dark [id*="cell"] {
    border-color: white;
  }
}

.board__container {
  aspect-ratio: 1/1;
  position: relative;
  min-height: 100px;
  min-width: 100px;
  width: 100%;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(3, 1fr);
}

[id="minigame-00"],
[id$="cell-00"] {
  border-bottom: 2px solid;
  border-right: 2px solid;
}

[id="minigame-01"],
[id$="cell-01"] {
  border-bottom: 2px solid;
  border-left: 2px solid;
  border-right: 2px solid;
}

[id="minigame-02"],
[id$="cell-02"] {
  border-bottom: 2px solid;
  border-left: 2px solid;
}

[id="minigame-10"],
[id$="cell-10"] {
  border-top: 2px solid;
  border-bottom: 2px solid;
  border-right: 2px solid;
}

[id="minigame-11"],
[id$="cell-11"] {
  border: 2px solid;
}

[id="minigame-12"],
[id$="cell-12"] {
  border-top: 2px solid;
  border-bottom: 2px solid;
  border-left: 2px solid;
}

[id="minigame-20"],
[id$="cell-20"] {
  border-top: 2px solid;
  border-right: 2px solid;
}

[id="minigame-21"],
[id$="cell-21"] {
  border-top: 2px solid;
  border-left: 2px solid;
  border-right: 2px solid;
}

[id="minigame-22"],
[id$="cell-22"] {
  border-top: 2px solid;
  border-left: 2px solid;
}
