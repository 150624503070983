@import "../../../utils/theme.scss";

.player-area {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  justify-self: center;
  width: fit-content;
  padding: 1rem;
  min-height: -webkit-fill-available;
  row-gap: 0.25rem;
  margin: 2rem 0 0 0;
  position: relative;
  background-color: transparent;

  @include sm {
    row-gap: 0.5rem;
  }

  &.your-turn {
    border: 1px solid black;
  }

  .move-timer {
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    border: none;
    width: 100%;
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    opacity: 0.5;
    z-index: 0;

    &::-webkit-progress-bar {
      background: none;
    }
  }

  .player-name-wrapper {
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
    transition: 0.3s ease-in-out;
    z-index: 1;

    &.dark {
      color: #efefef;
    }

    &:hover {
      .player-name {
        opacity: 0;
      }

      .name-prompt {
        opacity: 0.7;
      }
    }

    .player-name {
      text-align: center;
      font-size: 0.75rem;
      font-weight: bold;
      width: 100%;
      height: 100%;
      max-width: 12ch;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      margin: 0.5rem 0 0 0;
      background-color: transparent;
      transition: inherit;

      @include sm {
        font-size: 0.9rem;
        max-width: 15ch;
      }
      @include md {
        font-size: 1.1rem;
        max-width: 18ch;
      }
      @include lg {
        font-size: 1.3rem;
        max-width: 20ch;
      }
    }

    .name-prompt {
      position: absolute;
      margin: 0.5rem 0 0 0;
      opacity: 0;
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      font-weight: bold;
      transition: inherit;
      font-size: 0.7rem;

      @include md {
        font-size: 0.9rem;
      }
      @include lg {
        font-size: 1.1rem;
      }
    }
  }

  input {
    display: block;
    font-weight: bold;
    width: 100%;
    z-index: 1;
    background: transparent;
    border: none;
    text-align: center;
    margin: 0.5rem 0 0 0;

    @include sm {
      font-size: 0.9rem;
    }
    @include md {
      font-size: 1.1rem;
    }
    @include lg {
      font-size: 1.3rem;
    }
  }

  svg {
    aspect-ratio: 1/1;
    width: 50px;
    max-height: 70px;
    z-index: 1;

    @include sm {
      width: 65px;
    }
    @include md {
      width: 80px;
    }
    @include lg {
      width: 100px;
    }
  }

  &.winner svg {
    @include rainbowShadow;
  }
}
