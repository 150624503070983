.heading-wrapper {
  width: fit-content;
  position: relative;
  color: white;
  background-color: black;

  &.dark {
    color: black;
    background-color: white;
  }

  .heading {
    position: relative;
    width: fit-content;
    padding: 0 1rem;
    color: inherit;
  }

  h4 {
    font-size: 22px;
  }
}

h3 {
  margin-bottom: 1rem;
  font-size: 2rem;
}
