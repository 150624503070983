// Small tablets and large smartphones (landscape view)
$screen-sm-min: 576px;
// Small tablets (portrait view)
$screen-md-min: 768px;
// Tablets and small desktops
$screen-lg-min: 992px;
// Large tablets and desktops
$screen-xl-min: 1200px;

// Small devices
@mixin sm {
  @media (min-width: #{$screen-sm-min}) {
    @content;
  }
}
// Medium devices
@mixin md {
  @media (min-width: #{$screen-md-min}) {
    @content;
  }
}
// Large devices
@mixin lg {
  @media (min-width: #{$screen-lg-min}) {
    @content;
  }
}
// Extra large devices
@mixin xl {
  @media (min-width: #{$screen-xl-min}) {
    @content;
  }
}
// Custom devices
@mixin rwd($screen) {
  @media (min-width: $screen+"px") {
    @content;
  }
}

// fonts
$monospaceFont: "JetBrains Mono", monospace;

// colours
$whiteText: #efefef;
$darkThemeBg: #141414;
$darkModal: #1a1a1a;

// animations
@mixin placeToken {
  animation: placement 0.15s linear 1;

  @keyframes placement {
    0% {
      opacity: 0.5;
      transform: scale(2);
    }
    100% {
      opacity: 1;
      transform: scale(1);
    }
  }
}

@mixin placeTokenLong {
  animation: placement 0.3s linear 1;

  @keyframes placement {
    0% {
      opacity: 0.5;
      transform: scale(2);
    }
    100% {
      opacity: 1;
      transform: scale(1);
    }
  }
}

@mixin rainbowShadow {
  filter: drop-shadow(0.5rem 0.5rem 0.5rem #fb0094)
    drop-shadow(0.5rem -0.5rem 0.5rem #0000ff)
    drop-shadow(-0.5rem 0.5rem 0.5rem #00ff00)
    drop-shadow(-0.5rem -0.5rem 0.5rem #ffff00);
  animation: movingRainbow 3s linear infinite;
  animation-delay: 0.2s;

  @keyframes movingRainbow {
    0% {
      filter: drop-shadow(0.5rem -0.5rem 0.5rem #fb0094)
        drop-shadow(-0.5rem 0.5rem 0.5rem #0000ff)
        drop-shadow(-0.5rem -0.5rem 0.5rem #00ff00)
        drop-shadow(0.5rem 0.5rem 0.5rem #ffff00);
    }
    25% {
      filter: drop-shadow(-0.5rem 0.5rem 0.5rem #fb0094)
        drop-shadow(-0.5rem -0.5rem 0.5rem #0000ff)
        drop-shadow(0.5rem 0.5rem 0.5rem #00ff00)
        drop-shadow(0.5rem -0.5rem 0.5rem #ffff00);
    }
    50% {
      filter: drop-shadow(-0.5rem -0.5rem 0.5rem #fb0094)
        drop-shadow(0.5rem 0.5rem 0.5rem #0000ff)
        drop-shadow(0.5rem -0.5rem 0.5rem #00ff00)
        drop-shadow(-0.5rem 0.5rem 0.5rem #ffff00);
    }
    75% {
      filter: drop-shadow(0.5rem 0.5rem 0.5rem #fb0094)
        drop-shadow(0.5rem -0.5rem 0.5rem #0000ff)
        drop-shadow(-0.5rem 0.5rem 0.5rem #00ff00)
        drop-shadow(-0.5rem -0.5rem 0.5rem #ffff00);
    }
    100% {
      filter: drop-shadow(0.5rem -0.5rem 0.5rem #fb0094)
        drop-shadow(-0.5rem 0.5rem 0.5rem #0000ff)
        drop-shadow(-0.5rem -0.5rem 0.5rem #00ff00)
        drop-shadow(0.5rem 0.5rem 0.5rem #ffff00);
    }
  }
}

@mixin grey-overlay {
  background: rgba(#000000, 0.4);

  &::after {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    background: rgba(#ffffff, 0.5);
    z-index: 1;
  }
}
