@import "../../../../../utils/theme.scss";

.setting {
  display: flex;
  position: relative;
  justify-content: space-between;
  align-items: center;
  gap: 2rem;
  padding: 0 0 0.5rem 0;

  &:has(.switch) {
    padding-bottom: 0.1rem;

    &:last-of-type {
      padding-bottom: 0.5rem;
    }
  }

  input {
    text-align: center;
    width: 50px;
  }

  input[type="number"] {
    -webkit-appearance: textfield;
    -moz-appearance: textfield;
    appearance: textfield;
    border: none;
  }
  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
  }
  .input-wrapper {
    display: flex;

    .setting-increment-btn,
    .setting-decrement-btn {
      height: 35px;
      aspect-ratio: 1/1;
    }
  }

  &:hover {
    .slider {
      filter: drop-shadow(0 0 5px grey);
    }
  }
}
.switch {
  --button-width: 3.5em;
  --button-height: 2em;
  --toggle-diameter: 1.5em;
  --button-toggle-offset: calc(
    (var(--button-height) - var(--toggle-diameter)) / 2
  );
  --toggle-shadow-offset: 10px;
  --toggle-wider: 3em;
  --color-grey: #cccccc;
  --color-green: #42f445;
  display: flex;
  align-items: center;
}

.slider {
  display: inline-block;
  width: var(--button-width);
  height: var(--button-height);
  background-color: var(--color-grey);
  border-radius: calc(var(--button-height) / 2);
  position: relative;
  transition: 0.3s all ease-in-out;
}

.slider::after {
  content: "";
  display: inline-block;
  width: var(--toggle-diameter);
  height: var(--toggle-diameter);
  background-color: #fff;
  border-radius: calc(var(--toggle-diameter) / 2);
  position: absolute;
  top: var(--button-toggle-offset);
  transform: translateX(var(--button-toggle-offset));
  box-shadow: var(--toggle-shadow-offset) 0
    calc(var(--toggle-shadow-offset) * 4) rgba(0, 0, 0, 0.1);
  transition: 0.3s all ease-in-out;
}

.switch .setting-toggle:checked + .slider {
  background-color: var(--color-green);
}

.switch .setting-toggle:checked + .slider::after {
  transform: translateX(
    calc(
      var(--button-width) - var(--toggle-diameter) - var(--button-toggle-offset)
    )
  );
  box-shadow: calc(var(--toggle-shadow-offset) * -1) 0
    calc(var(--toggle-shadow-offset) * 4) rgba(0, 0, 0, 0.1);
}

.switch .setting-toggle {
  opacity: 0;
  position: absolute;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
}

.switch .setting-toggle:active + .slider::after {
  width: var(--toggle-wider);
}

.switch .setting-toggle:checked:active + .slider::after {
  transform: translateX(
    calc(
      var(--button-width) - var(--toggle-wider) - var(--button-toggle-offset)
    )
  );
}

.dark {
  input[type="number"] {
    color: $whiteText;
    background-color: $darkModal;
  }

  .slider {
    background-color: #454545;
  }
}
