@import "../../utils/theme.scss";

.rules-section {
  font-family: "JetBrains Mono", monospace;
  max-width: 800px;
  width: 95%;
  justify-self: center;
  padding-bottom: 10%;

  &.dark {
    color: $whiteText;
  }

  h2 {
    font-size: 3rem;
  }

  h3 {
    margin-top: 1rem;
  }

  h4,
  p:not(:last-of-type) {
    margin-bottom: 10px;
  }

  ul {
    margin-left: 1rem;
  }
}
