@import "../../../utils/theme.scss";

.cell {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5%;

  &:has(.X),
  &:has(.O) {
    padding: 0;
  }

  svg {
    // TODO: X's corners a little cutoff
    height: 95%;
    width: 95%;
    padding: 5%;

    &.animate {
      @include placeToken;
    }
  }
}
