@import "../../utils/theme.scss";
@import url("https://fonts.googleapis.com/css2?family=Fragment+Mono:ital@0;1&family=Major+Mono+Display&display=swap");

.scoreboard {
  display: grid;
  grid-template-columns: 1fr auto 1fr;
  position: relative;
  width: 90%;
  justify-self: center;
  justify-content: center;
  align-items: center;

  .score-area {
    padding: 0 1rem;
    white-space: nowrap;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: 0.3s ease-in-out;

    &:hover {
      .player-scores {
        opacity: 0;
      }
      .score-prompt {
        opacity: 0.7;
      }
    }

    .player-scores {
      font-family: "Fragment Mono", monospace;
      font-size: 1.3rem;
      font-weight: bold;
      transition: inherit;

      &.dark {
        color: #efefef;
      }

      @include sm {
        font-size: 1.5rem;
      }
      @include md {
        font-size: 2rem;
      }
      @include lg {
        font-size: 3rem;
      }

      .player-1-score {
        margin-right: 0.5rem;
        @include sm {
          margin-right: 0.65rem;
        }
        @include md {
          margin-right: 0.75rem;
        }
        @include lg {
          margin-right: 1rem;
        }
      }

      .player-2-score {
        margin-left: 0.5rem;
        @include sm {
          margin-left: 0.65rem;
        }
        @include md {
          margin-left: 0.75rem;
        }
        @include lg {
          margin-left: 1rem;
        }
      }
    }

    .score-prompt {
      position: absolute;
      font-weight: 500;
      opacity: 0;
      transition: inherit;

      @include sm {
        font-size: 1rem;
      }
      @include md {
        font-size: 1.5rem;
      }
      @include lg {
        font-size: 2rem;
      }
    }
  }
}
