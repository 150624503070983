@import "../../../../utils/theme.scss";

.won-game-modal {
  display: flex;
  flex-direction: column;
  justify-content: center;

  h3,
  p {
    text-align: center;
  }

  h3 {
    font-size: 26px;

    @include sm {
      font-size: 30px;
    }
    @include md {
      font-size: 34px;
    }
    @include lg {
      font-size: 38px;
    }
  }
}
