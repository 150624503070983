@import "../../../../utils/theme.scss";

.gear-icon {
  padding: 10px;
  width: 50px;
}

.settings-modal {
  font-size: 10px;
  padding: 0.5rem 1rem 1rem 1rem;

  @include sm {
    font-size: 16px;
  }
  @include md {
    font-size: 20px;
  }

  .settings-title {
    width: 100%;
    text-align: center;
    font-size: 16px;

    @include sm {
      font-size: 20px;
    }
    @include md {
      font-size: 26px;
    }
    @include lg {
      font-size: 30px;
    }
  }

  .message-area {
    text-align: center;
    padding: 0 2rem;

    .heading {
      font-weight: bold;
      margin-bottom: 1rem;

      @include sm {
        font-size: 20px;
      }
      @include md {
        font-size: 22px;
      }
      @include lg {
        font-size: 26px;
      }
    }
  }

  .settings-form {
    display: flex;
    flex-direction: column;
    font-size: inherit;
  }

  .buttons-area {
    display: flex;
    justify-content: space-around;
    margin-top: 1rem;
    min-width: 200px;

    @include sm {
      min-width: 250px;
    }
    @include md {
      min-width: 350px;
    }

    .btn {
      padding: 0.25rem 0.5rem;

      @include sm {
        padding: 0.4rem 0.8rem;
      }
      @include md {
        padding: 0.5rem 1rem;
      }
    }
  }
}
