@import "../../utils/theme.scss";

.game-buttons {
  display: flex;
  align-items: center;
  justify-content: center;
  top: 10px;
  right: 10px;
  z-index: 10;

  @include sm {
    flex-direction: column;
    position: fixed;
  }

  .play-icon {
    margin-left: 2px;
  }
}
