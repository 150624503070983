@import "../../../../utils/theme.scss";

.paused-modal {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 1rem 2rem;

  @include sm {
    padding: 1.5rem 3rem;
  }
  @include md {
    padding: 2rem 4rem;
  }

  h3 {
    text-align: center;
    font-size: 16px;

    &.dark {
      color: $whiteText;
    }

    @include sm {
      font-size: 20px;
    }
    @include md {
      font-size: 24px;
    }
  }

  .play-btn {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0.25rem 0.5rem;

    @include sm {
      padding: 0.4rem 0.8rem;
    }
    @include md {
      padding: 0.5rem 1rem;
    }

    strong {
      font-size: 12px;

      @include sm {
        font-size: 16px;
      }
      @include md {
        font-size: 20px;
      }
    }

    svg {
      aspect-ratio: 1/1;
      height: 1.5rem;
      margin-right: -0.5rem;

      @include sm {
        height: 1.75rem;
        margin-right: -0.8rem;
      }
      @include md {
        height: 2rem;
      }
    }
  }
}
